import axios, { AxiosInstance } from 'axios'

const createApiClient = (locale: string, apiUrl: string | undefined, appVersion: string): AxiosInstance => {
	return axios.create({
		baseURL: apiUrl,
		headers: {
			'Accept-Language': locale,
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Credentials': 'true',
			'Cache-Control': 'no-cache, no-store',
			Pragma: 'no-cache',
			'x-module-admin-version': appVersion
		}
	})
}

export default createApiClient
