import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { paths } from '../types/api'

type FilteredKeys<T, U> = {
	[P in keyof T]: T[P] extends U ? P : never
}[keyof T]

const fullFillURL = (urlTemplate: string, params: any) => {
	const pathParams = []
	const queryParams = { ...(params?.query || {}) }
	const fullfilURL = urlTemplate
		.split('/')
		.map((blok) => {
			if (/{[^}]*\}/.test(blok)) {
				const param = blok.replace('{', '').replace('}', '')
				pathParams.push(param)
				delete queryParams[param]
				return (params?.path || {})[param]
			}
			return blok
		})
		.join('/')
	return {
		fullfilURL,
		queryParams
	}
}

type HttpAbortRequest = 'httpGet'

/**
 * @type {Map<HttpAbortRequest, Map<string, AbortController>>} abort controllers for http requests
 */
export const abortControllers: Record<HttpAbortRequest, Map<string, AbortController>> = {
	httpGet: new Map<string, AbortController>()
}

export enum CANCEL_TOKEN_MESSAGES {
	CANCELED_DUE_TO_NEW_REQUEST = 'Operation canceled due to new request.'
}

/**
 * Builds an abort controller if allowAbort is true, and aborts the previous request if the abortControllerKey exists in the abortControllers map.
 *
 * @param {HttpAbortRequest} requestType - the type of the HTTP request
 * @param {string} abortControllerKey - the key used to identify the abort controller
 * @param {boolean} allowAbort - flag indicating whether to allow aborting
 * @return {object} an object with the signal property pointing to the abort controller's signal, if allowAbort is true; otherwise, an empty object
 */
const buildAbortController = (requestType: HttpAbortRequest, abortControllerKey: string, allowAbort?: boolean) => {
	if (!allowAbort) {
		return undefined
	}

	if (abortControllers[requestType].has(abortControllerKey)) {
		abortControllers[requestType].get(abortControllerKey)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_DUE_TO_NEW_REQUEST)
	}

	abortControllers[requestType].set(abortControllerKey, new AbortController())

	return abortControllers[requestType].get(abortControllerKey)?.signal
}

export interface ICustomConfig extends AxiosRequestConfig {
	displayNotification?: boolean
	allowAbort?: boolean
	abortSignalKey?: string
}

type RequestSchema = {
	parameters?: object | null
	requestBody?: {
		content: {
			'application/json': any
		}
	}
	responses?: {
		200: {
			content: {
				'application/json': any
			}
		}
	}
}

export type RequestParams<T extends RequestSchema> = T extends {
	parameters: infer Params
}
	? Params
	: never

export type RequestPayload<T extends RequestSchema> = T extends {
	requestBody?: {
		content: {
			'application/json': infer Payload
		}
	}
}
	? Payload
	: never

export type RequestResponse<T extends RequestSchema> = T extends {
	responses: {
		200: {
			content: {
				'application/json': infer Response
			}
		}
	}
}
	? Response
	: never

type ArgumentsBase<T extends RequestSchema> = {
	params: RequestParams<T>
	reqBody: RequestPayload<T>
	customConfig?: ICustomConfig
}

type RequestArguments<T extends RequestSchema> =
	RequestParams<T> extends never
		? RequestPayload<T> extends never
			? Omit<ArgumentsBase<T>, 'params' | 'reqBody'> | undefined
			: Omit<ArgumentsBase<T>, 'params'>
		: RequestPayload<T> extends never
			? Omit<ArgumentsBase<T>, 'reqBody'>
			: ArgumentsBase<T>

export type GetUrls = {
	[Q in FilteredKeys<paths, { get: RequestSchema }>]: paths[Q]['get']
}

const DEFAULT_CUSTOM_CONFIG: Pick<ICustomConfig, 'displayNotification' | 'abortSignalKey' | 'headers'> = {
	displayNotification: true,
	abortSignalKey: '',
	headers: {}
}

const prepareRequestConfig = (requestType: HttpAbortRequest, urlTemplate: string, params: any, customConfig?: ICustomConfig) => {
	const { fullfilURL, queryParams } = fullFillURL(urlTemplate, params)

	const config = { ...DEFAULT_CUSTOM_CONFIG, ...customConfig }
	const { abortSignalKey, allowAbort, headers } = config

	const signal = buildAbortController(requestType, abortSignalKey || fullfilURL, allowAbort)

	const requestConfig: ICustomConfig = {
		...config,
		signal,
		headers
	}

	if (queryParams) {
		requestConfig.params = queryParams
	}

	return {
		requestConfig,
		fullfilURL
	}
}

const EMPTY_ARGS = {
	params: undefined,
	customConfig: undefined,
	reqBody: undefined
}

export const getReq = async <T extends keyof GetUrls>(
	axiosClient: AxiosInstance,
	url: T,
	args: RequestArguments<GetUrls[T]>
): Promise<AxiosResponse<RequestResponse<GetUrls[T]>>> => {
	const { params, customConfig } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpGet', url, params, customConfig)

	try {
		const res = await axiosClient.get<RequestResponse<GetUrls[T]>>(fullfilURL, requestConfig)
		return res
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return Promise.reject(e)
	}
}
