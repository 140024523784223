import React from 'react'

import loadable from '@loadable/component'

import { ContextProps } from './types/types'

// server modifiers
import ApiProvider from './utils/apiProvider'

const LoadableAppLayout = loadable(() => import('./AppLayout'))

export interface IEntryPointProps extends ContextProps {
	fragmentType: string
}

const Entrypoint: React.FC<React.PropsWithChildren<IEntryPointProps>> = (props) => {
	switch (props.fragmentType) {
		case 'appLayout':
		default:
			return (
				<ApiProvider {...props}>
					<LoadableAppLayout {...props} />
				</ApiProvider>
			)
	}
}

export default Entrypoint
