import React, { useMemo, createContext } from 'react'

// api
import loadApiEndpoints, { ApiClient } from '../api/Salons'

// utils
import createApiClient from './axiosClientConfig'

type Props = {
	locale: string
	apiUrl: string
	appVersion: string
}

export interface IApiModel {
	apiBrowser: ApiClient
}

export const ApiContext = createContext<IApiModel>({
	apiBrowser: {} as ApiClient
})

/* Create api client for requests outside of server -> on client browser. If you want use this request you need to add your fetch function to return statement. */
const createBrowserApiClient = (props: Props): ApiClient => {
	const { locale, apiUrl, appVersion } = props
	// create axios instance
	const client = createApiClient(locale, apiUrl, appVersion)

	return {
		...loadApiEndpoints(client)
	}
}

const ApiProvider: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const value = useMemo(
		() => ({
			apiBrowser: createBrowserApiClient(props)
		}),
		[props]
	)

	return <ApiContext.Provider value={value}>{props.children}</ApiContext.Provider>
}

export default ApiProvider
