/* eslint-disable import/no-cycle */
import { MapType } from '../types/types'

export enum ENVIRONMENTS {
	development = 'development'
}

export enum HOSTS {
	developmentServer = 'developmentServer',
	testServer = 'testServer',
	productionServer = 'productionServer'
}

export enum SALON_TYPE {
	NON_BASIC = 'NON_BASIC',
	BASIC = 'BASIC'
}

export enum DAYS {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY'
}

export enum OPENING_HOURS_STATUS {
	ALL = 'ALL',
	OPEN = 'OPEN',
	CLOSED = 'CLOSED'
}

export const PDF_FILE_TYPE = 'application/pdf'

const COORDINATES = {
	PRAGUE: {
		lat: 50.08505813401708,
		lng: 14.419970052817515
	},
	BRATISLAVA: {
		lat: 48.152711345286555,
		lng: 17.110079398988983
	},
	BUCHAREST: {
		lat: 44.426765,
		lng: 26.102537
	},
	SOFIA: {
		lat: 42.697708,
		lng: 23.324272
	},
	BUDAPEST: {
		lat: 47.497912,
		lng: 19.040235
	}
}

export const MAP: MapType = {
	defaultZoom: 15,
	minLatitude: -90,
	maxLatitude: 90,
	minLongitude: -180,
	maxLongitude: 180,
	minZoom: 1,
	maxZoom: 20,
	placeZoom: 16,
	defaultLocation: COORDINATES.PRAGUE,
	locations: {
		cs: COORDINATES.PRAGUE,
		sk: COORDINATES.BRATISLAVA,
		bg: COORDINATES.SOFIA,
		hu: COORDINATES.BUDAPEST,
		ro: COORDINATES.BUCHAREST
	}
}

export enum SORTING_OPTION {
	RECOMMENDED = 'RECOMMENDED',
	CLOSEST = 'CLOSEST',
	BEST_RATED = 'BEST_RATED',
	CHEAPEST = 'CHEAPEST',
	MOST_EXPENSIVE = 'MOST_EXPENSIVE'
}

export enum OPEN_HOURS_STATE {
	CUSTOM_ORDER = 'CUSTOM_ORDER'
}

export enum SEARCH_FIELD_OPTION_TYPE {
	SALON = 'SALON',
	CITY = 'CITY'
}

export enum PAYMENT_METHOD {
	CARD = 'Card',
	CASH = 'Cash'
}

export const SALONS_SEARCH_DEBOUNCE_TIME_MS = 400
export const SALONS_MAP_DEBOUNCE_TIME_MS = 400
export const COSMETICS_SEARCH_DEBOUNCE_TIME_MS = 400
export const FILTER_COUNTS_CHANGE_DEBOUNCE_TIME_MS = 300

export const GA_EVENTS_DELAY = 100

export enum SALONS_FILTER_TYPE {
	PRICE = 'PRICE',
	AVAILABLE_RS = 'AVAILABLE_RS',
	OPENING_HOURS = 'OPENING_HOURS',
	RATING = 'RATING',
	COSMETICS = 'COSMETICS',
	DATE = 'DATE',
	LANGUAGES = 'LANGUAGES',
	SORTING = 'SORTING'
}

export enum RATINGS {
	ONE = 1,
	TWO = 2,
	THREE = 3,
	FOUR = 4,
	FIVE = 5
}

export enum AVAILABLE_RESERVATION_TYPE {
	PHONE_RESERVATION = 'PHONE_RESERVATION',
	ONLINE_RESERVATION = 'ONLINE_RESERVATION'
}

export enum RESERVATIONS_TIME_SLOTS {
	ANY = 'ANY',
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON',
	EVENING = 'EVENING'
}

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const DAYNAME_DATE_FORMAT = 'dd, D.MMM'
export const DAYNAME_DATE_YEAR_FORMAT = 'dd, D. MMM YY'
export const YEAR_MONTH_FORMAT = 'YYYY-MM'
export const FULL_MONTH_NAME = 'MMMM'

export const ARRAY_QUERY_PARAM_MAX_LENGTH = 50

export enum FORM {
	FILTER_SIDEBAR = 'FILTER_SIDEBAR',
	FILTER_SIDEBAR_PRICE = 'FILTER_SIDEBAR_PRICE',
	FILTER_SIDEBAR_EXACT_RATING = 'FILTER_SIDEBAR_EXACT_RATING',
	FILTER_SIDEBAR_COSMETICS = 'FILTER_SIDEBAR_COSMETICS',
	FILTER_SIDEBAR_LANGUAGES = 'FILTER_SIDEBAR_LANGUAGES',
	FILTER_SIDEBAR_DATE = 'FILTER_SIDEBAR_DATE',
	FILTER_SIDEBAR_ORDER_BY = 'FILTER_SIDEBAR_ORDER_BY'
}
