const React = require('react') // NOTE: import required even though not explicitly used used in this file
const Sentry = require('@sentry/react')

module.exports = () => ({
	enhanceApp: ({ DefaultApp, state }) => {
		const { rootWebViewProps } = state
		const { appVersion, sentryOptions } = rootWebViewProps

		// init sentry for client side
		Sentry.init({
			release: appVersion,
			enabled: sentryOptions.isEnabled,
			dsn: sentryOptions.dsn,
			integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({
				levels: ['error'], // Capture client console errors - available options ['log', 'info', 'warn', 'error', 'debug', 'assert']
			})],
			tracesSampleRate: 0.05,
			environment: sentryOptions.environment
		})
		return (props) => <DefaultApp {...props} />
	}
})
